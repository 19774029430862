@use "sass/font";

.TokenBalances {
    grid-area: TokenBalances;

    .TableRow-content--compact {
        &:not(&:first-of-type) {
            span:last-of-type {
                font-family: font.$code;
            }
        }
    }
}
