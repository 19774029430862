@use "sass/size";

.SettingsEditor {
    grid-area: SettingsEditor;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: size.$gap;
    height: 100%;

    grid-template-areas:
        "tokenWhitelist jobsSettings"
        "tokenWhitelist SEProposalForm";

    &-admins {
        grid-area: admins;
        display: none;
    }

    &-jobsSettings {
        grid-area: jobsSettings;
    }

    &-tokenWhitelist {
        grid-area: tokenWhitelist;
    }
}
