@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
.ExternalLoginDialog-stepByStepGuide {
  padding: 0 2.8rem;
  font-size: 1.8rem;
  list-style-type: decimal;
}

.ExternalLoginDialog-stepByStepGuide li span {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: .5ch;
  padding-left: .7rem;
  display: flex;
}

.ExternalLoginDialog-stepByStepGuide li span .MuiSvgIcon-root {
  color: #a4bab8;
  font-size: 2.3rem;
}

.ExternalLoginDialog-stepByStepGuide li span .MuiSvgIcon-root:hover, .ExternalLoginDialog-stepByStepGuide li span .MuiSvgIcon-root:focus {
  color: #dcefea;
}

.ExternalLoginDialog-stepByStepGuide li::marker {
  font-weight: 800;
}

.SettingsEditor-proposalForm {
  grid-area: SEProposalForm;
  gap: 1.12rem;
}

.SettingsEditor-proposalForm-hint {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem;
  display: flex;
}

.SettingsEditor-proposalForm-summary {
  gap: inherit;
  flex-flow: column;
  display: none;
}

.SettingsEditor-proposalForm-summary-entry {
  display: inherit;
  align-items: center;
  gap: inherit;
  flex-flow: wrap;
  padding: 0 1.12rem;
}

.SettingsEditor-proposalForm-summary-entry-description {
  white-space: nowrap;
  line-height: 1;
}

.SettingsEditor-proposalForm-summary-entry-data {
  display: inherit;
  flex-flow: wrap;
  gap: 1rem;
  padding: 0;
  line-height: 1;
  list-style: none;
}

.SettingsEditor-proposalForm-summary-entry-data-chip {
  border-radius: 2.4rem;
  padding: .5rem .6rem;
}

.SettingsEditor-proposalForm-summary-entry-data-chip--blue {
  background-color: #b2dff2;
}

.SettingsEditor-proposalForm-summary-entry-data-chip--green {
  background-color: #93c99e;
}

.SettingsEditor-proposalForm-summary-entry-data-chip--red {
  background-color: #f6a7b2;
}

.SettingsEditor-proposalForm-summary-entry-data-chip--yellow {
  background-color: #ffdf82;
}

.SettingsEditor-proposalForm-submit {
  justify-content: end;
  gap: inherit;
  display: none;
}

.SettingsEditor-proposalForm-hint, .SettingsEditor-proposalForm-submit {
  flex-flow: inherit;
  height: inherit;
}

.SettingsEditor-proposalForm.is-inEditMode .SettingsEditor-proposalForm-hint {
  display: none;
}

.SettingsEditor-proposalForm.is-inEditMode .SettingsEditor-proposalForm-summary, .SettingsEditor-proposalForm.is-inEditMode .SettingsEditor-proposalForm-submit {
  display: inherit;
}

.SettingsEditor {
  grid-template: "tokenWhitelist jobsSettings" 1fr
                 "tokenWhitelist SEProposalForm" 1fr
                 / 1fr 1fr;
  grid-area: SettingsEditor;
  gap: 2.8rem;
  height: 100%;
  display: grid;
}

.SettingsEditor-admins {
  grid-area: admins;
  display: none;
}

.SettingsEditor-jobsSettings {
  grid-area: jobsSettings;
}

.SettingsEditor-tokenWhitelist {
  grid-area: tokenWhitelist;
}

.light-textfield.MuiTextField-root {
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root:not(.Mui-error), .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused:not(.Mui-error) {
  color: #e0e0e0 !important;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root.Mui-error, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused.Mui-error {
  color: #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover {
  background-color: #edfdf90f !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:hover:before {
  border-bottom: 1px solid #edfdf96b;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:after {
  border-bottom: 2px solid #edfdf9;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:hover:before {
  border-bottom: 1px solid #f6a7b26b !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:after {
  border-bottom: 2px solid #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root input, .light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover input, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  color: #edfdf9;
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root :not(input) {
  color: #e0e0e0;
  font-size: 1em;
}

.spacer {
  flex: 1;
}

.loader {
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.loader:after {
  content: " ";
  border: 6px solid #a4bab8;
  border-color: #a4bab8 #0000;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sidebar-wrapper {
  z-index: 2;
  background-color: #323a3c;
  flex-flow: column;
  width: 5.6rem;
  height: 100%;
  display: flex;
  position: fixed;
  box-shadow: 1px 10px 20px #0003;
}

.sidebar-container {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.sidebar-container .title {
  cursor: pointer;
  padding: 1.65rem 0;
  position: relative;
}

.sidebar-container .title .logo {
  color: #e0e0e0;
  font-size: 3.45rem;
  display: block;
}

.sidebar-container .title .env {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 60%;
}

.sidebar-container .title .env .icon {
  color: #ffdf82;
  font-size: 1.8rem;
}

.sidebar-container .title .env[env="testnet"] {
  visibility: visible;
}

.sidebar-container nav {
  flex-flow: column;
  font-size: 1.8rem;
  display: flex;
}

.sidebar-container nav a {
  text-align: center;
  vertical-align: middle;
  width: 5.6rem;
  height: 5.6rem;
  font-size: 1.8rem;
  line-height: 5.6rem;
  color: #e0e0e0 !important;
}

.sidebar-container nav a.active {
  background-color: #edfdf9;
  color: #2a2a2a !important;
}

.sidebar-container nav a:not(.active):hover {
  background-color: #b4ccca1a;
}

.sidebar-container hr {
  width: 40%;
}

.sidebar-container img {
  fill: #b4ccca;
  cursor: pointer;
  opacity: .25;
  width: 2.8rem;
  height: 2.8rem;
  padding: 1.4rem;
}

.sidebar-container img:hover {
  opacity: 1;
}

.sidebar-button.MuiButtonBase-root {
  padding: 0;
}

.sidebar-button .MuiSvgIcon-root {
  color: #b4ccca;
  cursor: pointer;
  padding: 1.4rem;
  font-size: 3.45rem;
}

.sidebar-button .MuiSvgIcon-root:hover {
  background-color: #b4ccca1a;
}

.extras {
  margin-top: auto;
}

.extras .socials {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.extras .legal-disclaimer {
  max-width: 25ch;
  font-size: 1.4rem;
}

.TokenBalances {
  grid-area: TokenBalances;
}

.TokenBalances .TableRow-content--compact:not(.TokenBalances .TableRow-content--compact:first-of-type) span:last-of-type {
  font-family: Roboto Mono, monospace;
}

.DaoSettingsTab {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: 2.4rem;
  overflow-y: scroll;
  margin: 2.8rem !important;
  padding: 0 !important;
}

.DaoSettingsTab::-webkit-scrollbar {
  display: none;
}

.DaoPage {
  flex-flow: column;
  width: calc(100% - 5.6rem);
  height: 100%;
  display: flex;
  position: relative;
  left: 5.6rem;
}

.DaoPage-header {
  flex-flow: row;
  flex-shrink: 0;
  flex-basis: 6.75rem;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  min-width: 50ch;
  max-width: 140rem;
  margin: 0 auto;
  display: flex;
}

.DaoPage-header .DaoSearch {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.DaoPage-header .DaoSearch form {
  width: 100%;
}

.DaoPage-header .DaoSearch .MuiTextField-root {
  width: 100%;
  max-width: 100ch;
}

.DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline {
  font-size: 2.3rem;
}

.DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline input {
  padding: 0 0 0 1.4rem;
  font-size: 1em;
}

.DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline:hover:before, .DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline:before {
  border-bottom-color: #839595;
}

.DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline:hover:after, .DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline:after {
  border-bottom-color: #637171;
}

.DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline input {
  text-align: center;
  font-family: Titillium Web, sans-serif;
  font-weight: 500;
  color: #637171 !important;
}

.DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline input:active, .DaoPage-header .DaoSearch .MuiTextField-root .MuiInput-underline input:focus {
  text-align: left;
}

.DaoPage-header .DaoSearch .MuiTextField-root fieldset {
  border: none;
  border-bottom: 2px solid;
  border-radius: 0;
}

.DaoPage-header .DaoSearch .MuiTextField-root p {
  display: none;
}

.DaoPage-header form {
  z-index: 1;
}

.DaoPage:before {
  content: "";
  background-color: #b4ccca;
  min-width: 100%;
  height: 6.75rem;
  position: fixed;
  top: 0;
  left: 0;
}

.DaoPage-content {
  gap: 2.8rem;
  width: 100%;
  max-width: 120rem;
  height: 100%;
  max-height: calc(100% - 5.6rem);
  margin: 0;
  padding: 2.8rem;
  position: relative;
}

.DaoPage-content.error {
  flex-flow: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-size: 1.8rem;
  display: flex;
}

.DaoPage-content.error p.red {
  color: #f6a7b2;
}

.DaoPage-content.error p.green {
  color: #93c99e;
}

.DaoPage-content.error .Alert {
  color: #637171;
  max-width: 40em;
  font-size: 1.8rem;
}

.DaoPage-content.error .create-multicall {
  background-color: #93c99e;
  border-radius: .5em;
  align-self: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25em .75em;
  font-size: 1.8rem;
}

.DaoPage-content.error .create-multicall.proposal-exists {
  background-color: #b2dff2 !important;
}

.DaoPage-tabs {
  height: calc(100% - 6.75rem);
}

.DaoPage-tabs-buttonsPanel {
  justify-content: flex-start;
  max-width: 120rem;
  margin: 2.8rem auto 0;
  padding: 0 2.8rem;
}

.DaoPage-tabs-contentSpace {
  height: calc(100% - 6.72rem);
}

.DaoFundsTab {
  grid-template: "TokenBalances TokenBalances" 1fr
                 "TokenBalances TokenBalances" 1fr
                 / 1fr 1fr;
  display: grid;
}

.DaoJobsTab {
  grid-template: "JobEntriesTable JobEntriesTable" 1fr
                 "JobEntriesTable JobEntriesTable" 1fr
                 / 1fr 1fr;
  display: grid;
}

/*# sourceMappingURL=dao.bbaa0daa.css.map */
